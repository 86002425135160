import './header.scss'

function Header() {
    
    return (
        <div id="home" className="header-container">
            <h1 data-heading='Security'>Israeli Bodyguards</h1>
        </div>
    )
}

export default Header;